import { render, staticRenderFns } from "./index.vue?vue&type=template&id=046a350a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=046a350a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "046a350a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnVideo: require('/home/php1/m-nuxt/components/YfnVideo.vue').default,YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
